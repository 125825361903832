#pink {
  color: #d048b6;
}

.poll {
  position: relative;
  padding-bottom: 1em;
  padding-top: .25em;
}

.poll-info {
  mix-blend-mode: normal;
  padding-top: 2.5px;
  width: 80%;
  float: left;
}

.poll-title {
  font-size: 20px;
  font-weight: 400;
  color: white;
  opacity: 0.8;
}

.poll-description {
  padding-top: 5px;
  color: #525f7f;
}

.feed-poll .poll-description,
.feed-poll .poll-title {
  display: block;
  overflow: hidden;
}

.feed-poll .poll-description {
  -webkit-line-clamp: 1;
}

a .card {
  color: #525f7f;
}

.poll-qr {
  text-align: center;
  padding-bottom: 25px;
  padding-top: 25px;
  margin-bottom: 12.5px;
  margin-left: 62.5px;
  margin-right: 62.5px;
}

.poll-details {
  padding: 0px 12.5px 25px 12.5px;
  mix-blend-mode: normal;
  margin-top: -7.5px;
  font-size: 22.5px;
  font-weight: 300;
  color: white;
  opacity: 0.8;
}

.poll-profile {
  width: 50px;
  height: 50px;
  border-radius: 30px;
}

.poll-info .poll-issuer {
  display: block;
}

.poll-issuer {
  border: solid 2px rgba(255, 0, 0, 0);
  border-radius: 30px;
  margin-right: 15px;
  float: left;
}

.vote-body {
  padding: 5px 15px 15px 82.5px !important;
}

.poll-details .eth-icon-xl {
  margin-left: 25px;
}

.poll-result {
  display: table;
  width: 100%;
  font-weight: 300;
  float: left;
  font-size: 20px;
  white-space: nowrap;
}

.poll-result ul {
  list-style: none;
  list-style-type: none;
  margin: 20px 0px;
  padding: 0px;
  text-align: center;
}

.poll-result li {
  display: inline;
}

.poll-result li:first-of-type {
  float: left;
}

.poll-result li:last-of-type{
  float: right;
  color: #d048b6;
}

.poll-result li:last-of-type:hover{
  color: white !important;
}

.vote-body .chart-container {
  transition: opacity .35s ease-in;
  position: absolute;
  visibility: hidden;
  margin-left: 80px;
  width: calc(100% - 4.5em);
  transform: translateY(-128.5%);
  height: 130px;
  opacity: 0;
  z-index: 1;
  left: -.5%;
}

.poll-result li:last-of-type:hover~.chart-container,
.poll-result li:last-of-type:hover~.chart-container{
  background: #2a2b40;
  visibility: visible;
  opacity: 1;
  z-index: 10;
}
