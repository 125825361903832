@media screen and (max-width: 425px) and (max-height: 825px) {

  .profile-traits {
    top: 5px;
  }

  .poll-title  {
    font-size: 18.75px;
  }

  .create-poll-inputs {
    width: 75%;
  }

  .feed-poll .chart-container {
    display: none;
  }

  .container .row {
    margin-left: auto;
    margin-right: auto;
  }

  .brightid-logo {
    margin-right: 10px;
  }

  .container {
    padding: 7.5em .5em;
  }

  .fixed-top .container {
    padding: 0em .5em;
  }

  .navbar {
    padding: 0px 5px 0px 5px !important;
  }

  .modal-content .modal-body {
    overflow-wrap: break-word;
    align-self: auto;
  }

  .poll-qr {
    margin-left: 0px;
    margin-right: 0px;
  }

  .modal-dialog .btn {
    font-size: 0.6rem;
  }

  .navbar .navbar-nav .dropdown {
    margin: 1.5px 0px;
  }

  .profile-traits {
    white-space: normal !important;
  }

  .profile-traits li:last-of-type {
    padding-left: 5%;
  }

  .fixed-top .container .row {
    margin-left: auto;
    margin-right: auto;
  }

 .col-sm-12 {
    padding-right: 20px;
    padding-left: 20px;
  }

  .navbar .navbar-brand {
    margin: 0;
  }

  .navbar-nav .nav-link {
    padding-right: 10px !important;
  }

  .poll-result {
    font-size: 17.5px;
  }

  .poll-result li:last-of-type {
      display: none;
      float: right;
   }

  .poll-result li:first-of-type {
      float: left !important;
      padding-left: 12.5px;
   }

  .nav-profile-alt, .nav-profile {
    height: 27.5px;
    width: 27.5px;
  }

  .nav-profile-alt {
    margin-right: 2.5px;
  }

  .nav-profile-alt .fa-user-circle {
    font-size: 26.75px !important;
  }

  .poll-info {
    width: 60vw;
  }

  .feed-poll .card-body {
    padding: 0 1.25rem;
  }

  .dropdown-menu  {
    left: -52.5px !important;
  }

  .poll-issuer {
    margin-right: 12.5px !important;
    width: 50px;
  }

  .poll-details {
    font-size: 15px !important;
  }

  .new-poll .card-body {
    padding: 0px;
    padding-bottom: 12.5px;
  }

  .modal-dialog {
    margin: 0rem 1.5rem
  }

  .profile-address {
    font-size: 16.75px;
    display: block;
  }


  .profile-blockie {
    width: 50px;
    height: 50px;
    float: left;
  }

  /* Galaxy S5 */

  @media screen and (width: 360px) and (height: 640px) {


    .poll-info {
      width: 50vw;
    }

    .poll-result li:first-of-type {
      float: none !important;
      padding-right: 12.5px;
    }

    .eth-icon {
      margin-bottom: 0px;
      height: 15px;
    }

    .profile-address {
      font-size: 15px;
    }

  }

  /* Pixel 2 */

  @media screen and (width: 411px) and (height: 731px) {

  }

  /* Pixel 2 XL */

  @media screen and (width: 411px) and (height: 823px) {

  }

  /* iPhone X */

  @media screen and (width: 375px) and (height: 812px) {

    .eth-icon {
      padding-bottom: .5px;
      height: 22.5px;
    }

  }

  /* iPhone 6/7+ */

  @media screen and (width: 414px) and (height: 736px) {

  }

}

/* iPad */

@media screen and (width: 768px) and (height: 1024px) {

  .dropdown-menu  {
    left: -77.5px !important;
  }

  .modal-dialog {
    margin: 1.75rem auto !important;
  }

  .eth-icon {
    margin-bottom: 0px;
  }

  .poll-info {
    max-width: 25vw;
  }

  .profile-address{
    font-size: 15px;
  }


}

@media screen and (max-width: 350px) {


  .poll-result {
    font-size: 15px;
  }

  .profile-traits li:first-of-type{
    padding-left: 5%;
  }

   .profile-traits li:nth-of-type(2) {
    padding-left: 5%;
    padding-right: 5%;
  }

  .navbar-nav .nav-link {
    padding-right: 10px !important;
  }

  .nav-profile {
    width: 20px;
    height: 20px;
  }

  .new-poll-profile {
    width: 40px;
    height: 40px;
  }

  .navbar .navbar-nav .btn {
    margin-left: -15px;
  }

  .card-body {
    font-size: 12px;
  }

  .poll-info {
    max-width: 40vw;
  }

  .eth-icon {
    height: 15px;
  }

  .poll-title, .poll-description {
    font-size: 13.75px;
  }

  .poll-profile {
    width: 40px;
    height: 40px;
  }

  .nav-profile-alt .fa-user-circle {
    font-size: 22.5px !important;
  }

  .navbar-title {
    margin-left: 0px;
    font-size: 17.5px;
  }

}

@media screen and (max-width: 276px) {


  .poll-result {
    font-size: 12.5px;
  }

  .poll-info {
    max-width: 40vw;
  }

  .nav-link {
    padding-right: 0px !important;
  }

  .nav-profile-alt .fa-user-circle {
    font-size: 15px !important;
  }

  .navbar-title {
    font-size: 13.75px;
  }

  .navbar-logo {
    width: 30px;
    height: 30px;
  }

}

/* iPhone 5 */

@media screen and (width: 320px) and (height: 568px) {

  .navbar-nav .nav-link {
    padding-right: 20px !important;
  }

  .nav-profile {
    width: 27.5px;
    height: 27.5px;
  }

  .poll-description {
    font-size: 13.75px;
  }

  .poll-title {
    font-size: 17.5px;
  }

  .poll-info {
    max-width: 50vw;
  }

  .fixed-top .container .row {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .profile-address{
    font-size: 12.5px;
  }

  .poll-result li:first-of-type {
    padding-right: 12.5px;
  }

  .eth-icon {
    margin-bottom: .5px;
    height: 22.5px;
  }

  .navbar-title {
    font-size: 17.5px;
  }

  .poll-result {
    font-size: 15px;
  }

  .poll-details {
    font-size: 12.5px !important;
  }

}
