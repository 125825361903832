.profile .card-body {
  padding: .5em;
}

.profile .card ul  {
  list-style: none;
  padding-bottom: -20px;
}

.profile .brightid-logo {
  width: 15px;
  height: 15px;
  margin-left: 10px;
  margin-top: -2.75px;
}

.profile .card li {
  transition: 0.35s;
}

.profile .card li:hover {
  color: #d048b6;
}

.profile .card li:before {
  content: '\2022';
  color: #d048b6;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}

.profile-blockie {
  border-radius: 75px;
  margin-right: 12.5px;
  width: 50px;
  height: 50px;
  float: left;
}

.profile-traits {
  display: table;
  font-weight: 300;
  font-size: 15px;
  white-space: nowrap;
}

.card-header .profile-traits ul {
  text-align: left;
  padding: 0px;
}

.card-header .profile-traits li {
  display: inline-block;
}

.profile-traits li:first-of-type{
  padding-left: 5%;
}

 .profile-traits li:nth-of-type(2) {
  padding-left: 10%;
}

 .profile-traits li:last-of-type {
   padding-left: 10%;
}

a .profile-traits {
  display: block;
}

.profile-traits {
  position: relative;
  display: inline-block;
  color: #525f7f;
  float: left;
}

.profile-address {
  font-size: 16.75px;
  padding-bottom: 7.5px;
  color: white;
  overflow-wrap: break-word;
}

.profile-address a {
  color: white;
}

.profile-address a:hover {
  color: #d048b6;
}

.profile-verified {
  color: #525f7f !important;
  padding-top: 12.5px;
  padding-bottom: 7.5px;
  font-weight: 400;
}
